



























import { Vue, Component, Prop, mixins } from 'nuxt-property-decorator';
import { BlokHeadingBox } from '../../../types';
import BreakpointMixin from '../../../mixins/BreakpointMixin';
import LinkManual from '../../utilities/links/LinkManual.vue';

@Component({
  components: { LinkManual }
})
export default class HeadingBox extends mixins(BreakpointMixin) {
  @Prop() blok!: BlokHeadingBox;
  // -> GSAP animation
  $refs!: { beam: Element, title: Element };
  animate (): void {
    const timeline = this.$gsap.timeline({
      defaults: { duration: 0.5, ease: 'power1.out', delay: 0.1 },
      scrollTrigger: {
        trigger: () => this.$refs.title,
        start: 'top bottom'
      }
    });
    timeline.fromTo(this.$refs.title, { yPercent: 100 }, { yPercent: 0 });
    timeline.from(this.$refs.beam, { width: 0 });
  }
  mounted () {
    if (!process.browser) { return; }
    this.$gsap.set(this.$refs.title, { yPercent: 100 });
    this.animate();
  }
}
